@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');
@import "react-slideshow-image/dist/styles.css";
*{
  margin: 0;
}
body {
  box-sizing: border-box;
  margin-bottom: 0;
  min-height: 100vh;
  font-family: 'Poppins';
}
.navbar{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000A;
  opacity: 1;
  height: 112px;
}
.navbar-nav{
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font-family: 'Poppins';
  letter-spacing: 0.3px;
  color: #111111;
  text-transform: capitalize;
  font-size: 15px;
  opacity: 1;
  font-weight: 500;
}
.navbar-nav:hover{
  color: #111111;
  font-size: 15px;
  font-weight: 500;
  font-family: "poppins";
}
.nav-link.contact-us{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #008848!important;
  font-family:'Poppins';
  letter-spacing: 0.32px;
  font-weight: 500;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;
  border: 2px solid #008848;
  margin-left: 30px;
}
.css-line{
  position: absolute;
  bottom: -1px;
  height: 4px;
  background: rgb(97, 218, 251) none repeat scroll 0% 0%;
  left: 0px;
  right: 0px;
  z-index: 1;
}
.each-slide-effect{
  margin-top: 22px;
  background: #E8EFEC 0% 0% no-repeat padding-box;
}
.slide{
  margin-top: 45px;
}
.each-slide-effect > div {
  display: flex;
  height: 478px;
  align-items: center;
}
.slide-heading{
  margin-left: 45px;
  margin-top: 45px;
  font-family: 'Poppins';
  font-size: 60px;
  font-weight: bold;
}
.development-head{
  margin-left: 45px;
  margin-top: 45px;
  font-family: 'Poppins';
  font-size: 60px;
  font-weight: bold;
}
.slide-txt{
  color: #008848;
  font-size: 30px;
  line-height: 34px;
  margin-left: 45px;
  width: 60%;
}
.main-heading{ 
  font-family: 'Poppins';
  text-align: left;
  letter-spacing: 0.72px;
  font-size: 36px;
  font-weight:600;
  color: #111111;
  width: 80%;
}
.owner-heading{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 0.72px;
  font-size: 26px;
  font-weight:400;
}
.main-heading-1{ 
  font-family: 'Poppins';
  text-align: left;
  letter-spacing: 0.72px;
  font-size: 36px;
  font-weight:600;
  margin-bottom: 20px;
  color: #111111;
  width: 80%;
}
.dropdown-menu{
  border: none!important;
}
.dropdown-menu.show{
    text-align:left;
}
.dropdown-item{
  color: #777777;
}
.row{
  margin-top: 20px;
}
.show-grid{
  margin-top: 230px;
}
.heading-end{
  text-align: center;
  margin-top: 140px;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight:600;
}
.img-slider{
  width: 100%;
}
.heding2{
  font-size: 40px;
  margin-top: 45px;
  color: #2B4137;
  text-align: center;
  margin-bottom: 30px;
  font-family:'Poppins';
  font-weight: 600;
  size: 36px;
  letter-spacing: 0.72px;
}
.bttn{
  padding: 50px 100px 50px 100px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 60px;
}
.para-div{
  padding: 120px 0px 0px 40px;

}
.para-div-1{
  /* padding: 100px 0px 0px 0px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: left;
  height: 350px;
}
.para-div1{
  padding: 20px 0px 0px 40px;
}
.para-div1 h5{
  color:  #2B4137;
  font-size: 20px;
  line-height: 46px;
  font-family: 'Poppins';
}
.para-div1 p{
  font-family: 'Poppins';
  color: #222222;
  font-size: 14px;
  line-height: 21px;
}
.para-div h5{
  color:  #2B4137;
  font-size: 20px;
  line-height: 46px;
  font-family: 'Poppins';
}
.para-div p{
  font-family: 'Poppins';
  color: #222222;
  font-size: 14px;
  line-height: 21px;
}
.bttn h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #111111;
  letter-spacing: 0px;
}
.bttn .services-para{
  text-align: center;
  color: #222222;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 23px;
  margin-bottom: 40px;
}
.services-para-3{
  text-align: center;
  color: #222222;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 23px;
  margin:20px 0;
}
.tabs-link{
  display: flex;
  flex-direction: column;
}
.nav-item{
  background-color:#FFFFFF;
  height: 65px;
  color:#222222;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 2px;
  font-size: 18px;
  box-shadow: 0px 2px 6px #00000014;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: #F6F6F6;
  color: #222222;
}
.navlink{
    color: #222222;
    padding: 20px;
    height: 65px;
}
.navlink img{
  float: right;
}
.navlink1{
  color: #222222;
  padding: 20px;
  height: 65px;
  text-align: end;
}
.navlink1 img{
float:left;
}
.nav-item :hover{
  background: #F6F6F6;
  border: 1px solid #E8E8E8;
  border: none;
  color: #222222;
}
.heading-senior{
  text-align: center;
  margin-top: 40px;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight:600;
}
.architectures{
  text-align: center;
  margin-top: 80px;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight:600;
}
.social-follow{
margin-top: -15px;
}
.social-footer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -25px;
}
.bttn1{
  padding: 40px 10px 20px 40px;
  background: #DBEBE5 0% 0% no-repeat padding-box;
  border-radius: 18px;
}
.btn1{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000014;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-family:'Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  border: none;
  margin-bottom: 20px;
  width: 95%;
  height: 65px;
}
.btn1:hover{
  background: #008848 0% 0% no-repeat padding-box!important;
  border: none;
  color: #222222;
}
.btn2{
  background: #F5F9F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001A;
  font-family:'Poppins';
  border-radius: 10px;
  color: #222222;
  border: none;
  height: 63px;
  padding: 0px 25px;
  font-size: 16px;
}
.btn2:hover{
  background: #E8E8E8 0% 0% no-repeat padding-box!important;
  color: #222222;
}
.last_row{
  justify-content: center;
  margin-bottom: 50px;
}
.cloud-img{
  float: right;
}
.footer{
  max-width: 100%!important;
  background: #fafafa 0% 0% no-repeat padding-box;
  height: 411px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-text{
  width: 72%;
}
.foot-col p{
  margin-bottom: 5px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  font-weight: 300;
}
.foot-col>p>a{
  padding: 0px!important;
  color: #222222;
}
.foot-col>p>a:hover{
  color: #222222;
}
.foot-heading h3{
  color:#008848;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0px;
}
.foot-heading p{
  color: #222222;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}
.foot-col h3{
  font-size: 24px;
  color: #111111;
  font-family: 'Poppins';
  font-weight: 600;
  text-align: left;
  line-height: 40px;
}
.row-first{
  height: 100%;
}
.row-end{
  margin-top: 70px;
  border-top: 2px solid #E8E8E8;
}
.rights-text{
  margin-top: 30px;
  text-align: center;
}
.f-img{
  padding: 10px 0 5px 0;
  float: right;
}
.form{
  background: #DBEBE5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 10px;
  margin-bottom: 20px;
}
.btn-form{
  background-color: #008848!important;
  border: none;
  height: 40px;
  margin-top: 10px;
}
.contact-heading{
  margin-top: 100px;
  display: block;
  text-align: center;
  font-family: "Poppins";
}
.main-div{
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  
}
.main-div-c{
  display:block;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.box-div{
  /* padding: 20px 10px 20px 40px; */
  width: 90%;
}
.box-div2{
  padding: 40px 10px 20px 40px;
  border-radius: 18px;
}
.box-div1{
  display: flex;
  justify-content: center;
}
.owner-div1{
  padding: 40px 10px 20px 40px;
  border-radius: 30px;
  background-color:#e8e8e8;
  height: 228px;
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  z-index: 0;
  margin-top: -70px;
}
ul.indicators li{
  padding: 10px!important;
}
ul.indicators .each-slideshow-indicator::before{
  background-color: #19CE78!important;
  width: 13px!important;
  height: 13px!important;
}
.video-link{
  box-shadow: 0px 30px 40px #29503E42;
  border: 2px solid #DEF9EC;
  border-radius: 18px;
  opacity: 1;
}
.img-div{
  border-radius: 18px;
  border:1px solid #DEF9EC;
}
.top-div
{
  padding: 40px 20px 10px 40px;
  opacity: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.services_rectangle{
  background: #F6F6F6 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000014;
  border-radius: 10px;
  opacity: 1;
  padding: 30px;
  margin:10px auto;
  /* margin-bottom: 20px; */
}
.services_rectangle_web{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000014;
  border-radius: 10px;
  opacity: 1;
  padding: 30px;
  margin:10px auto;
  /* margin-bottom: 20px; */
}
.img-rec{
  margin-bottom: 20px;
}
.owner-paragraph{
  font-family: 'Poppins';
  text-align: center;
  opacity: 1;
  padding-right: 0.75rem;
  font-size: 16px;
}
.paragraph{
  font-family: 'Poppins';
  text-align:justify;
  color: #222222;
  opacity: 1;
  padding-right: 0.75rem;
  font-size: 15px;
  line-height: 23px;
}
.paragraphs1{
  font-family: 'Poppins';
  text-align:left;
  color: #222222;
  opacity: 1;
  padding-right: 0.75rem;
  font-size: 15px;
  line-height: 23px;
  width: 87%;
}
.service-heading{
  text-align: left;
  font-family:'Poppins';
  font-size: 20px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
.service-paragraph{
  text-align: left;
  font-family:'normal','normal','Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 0.8;
  font-size: 15px;
}
.customize-heading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}
.customize-heading1{
  text-align: center;
  width: 60%;
  margin:0 auto;
}
.customize{
  font-family: 'Poppins';
  font-weight:600;
  font-size: 36px;
  letter-spacing: 0.72px;
  color: #2B4137;
  opacity: 1;
  line-height:46px;
  margin-bottom: 25px;
}
.rect-text{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  padding: 30px 40px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rect-text1{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  padding: 30px 40px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rect-text1 p{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  font-family:'Poppins';
  letter-spacing: px;
  color: #222222;
  opacity: 0.8;
}
.rect-text1 h4{
  text-align: left;
  font-size: 20px;
  width: 90%;
  line-height: 30px;
  font-family: 'Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  font-weight:600;
  margin-bottom: 10px;
}
.rect-text h4{
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  font-weight:600;
  margin-bottom: 20px;
}
.rect-text p{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  font-family:'Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 0.8;
  width: 85%;
}
.request-demo{
  margin-top: 80px;
}
.Demo-row{
  margin-top: 120px;
}
.request-demo h3{
  text-align: left;
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  width: 80%;
  font-family:'Poppins';
  letter-spacing: 0.72px;
  color: #111111;
  opacity: 1;
}
.request-demo p{
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  font-family: 'Poppins';
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
.service-form{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 50px #29503E1A;
  border: 2px solid #DEF9EC;
  border-radius: 18px;
  opacity: 1;
  padding: 30px;
}
.form-input{
  padding: 18px;
  border: 1px solid #CECECE;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  font-weight: 'Poppins';
  letter-spacing: 0.45px;
  color: #C1C1C1;
  text-transform: capitalize;
  opacity: 1;
}
.form-label1{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  font-family:'Poppins';
  letter-spacing: 0.45px;
  color: #008848;
  text-transform: capitalize;
  opacity: 1;
}
.form-row{
  margin-left: 2px;
  margin-top: -5px;
}
.button-form{
  margin-top: 10px;
  background: #008848 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  font-family:'Poppins';
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.button-form:hover{
  background: #008848 0% 0% no-repeat padding-box;
}
.buttn{
  background-color: #008848;
  color: #FFFFFF;
  border-radius: 10px;
  border: none;
  height: 50px;
  width: 180px;
  border: none;
  outline: none;
}
.buttn:hover{
  background-color: #008848;
    border: none;
    outline: none;
}
.experience-rect{
  background: #F2F8F5 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  width: 100%;
  padding: 20px 50px 40px 50px;
  margin-top: 80px;
}
.experience-rect-1{
  background: #F2F8F5 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  width: 100%;
  padding: 20px 50px 40px 50px;
  margin-top: 40px;
}
.check-img{
  margin-bottom:20px;
}
.button-rectangle{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000014;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  padding: 30px 40px;
  align-items: center;
  height: 10%;
  margin-bottom: 70px;
  margin-top: 70px;
}
.bttn-rec-text{
  text-align: left;
  font-family:'Poppins';
  font-size: 24px;
  letter-spacing: 0px;
  color: #008848;
  opacity: 1;
  line-height: 35px;
}
.rect-bttn{
  background: #f5f9f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  border: none;
  color:#222222;
  font-size: 15px;
  font-family: "Poppins";
  margin-left: 5px;
  height: 60px;
  width: 220px;
}
.rect-bttn:hover{
  background: #f6f6f6 0% 0% no-repeat padding-box;
}
/* .experience-heading{
  text-align: left;
  font-family:'Poppins';
  font-size: 21px;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
} */
.heading-end1{
  text-align: center;
  margin-top: 50px;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight:600;
}
.experience-paragraph{
  padding-left: 70px;
}
.inline-div{
  display: flex;
}
.inline-div>h5{
  padding-left: 20px;
}
.line{
  background: #19CE78 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 4px;
  width: 30%;
  margin-top: 30px;
}
.drop-link{
  color: #777777;
  text-decoration: none;
}
.drop-link:hover{
  color: #111111;
}
.App{
  transform:scale(0.88);
  -webkit-transform: scale(.88);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.banner-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner-row{
  background-color:#F2F8F5;
  margin-top: 55px;
}
.box{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.website-heading{
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0.4px;
}
.development-process{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
}
.development-process>div{
  padding: 60px;
}
.development-process-2{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
}
.development-process-2>div{
  padding: 60px;
}
.dev-heading{
  text-align: center;
  font-size:36px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 80%;
  margin: 0 auto;
}
.dev-para{
  text-align: center;
  font-size:18px;
  line-height: 27px;
  padding-top: 10px;
  padding-bottom: 40px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
@media only screen and (min-width:1200px) and (max-width:1400px){
  .slide-heading{
    margin-left: 0px;
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
  }
  .development-head{
    margin-left: 0px;
    margin-top: 45px;
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
  }
  .slide-txt{
    color: #008848;
    font-size: 30px;
    margin-left: 0px;
    line-height: 34px;
    width: 70%;
  }
}
@media only screen and (min-width:992px) and (max-width:1200px){
  .slide-heading{
    margin-left: 45px;
    font-family: 'Poppins';
    font-size: 40px;
    font-weight: bold;
  }
  .development-head{
    margin-left: 45px;
    margin-top: 45px;
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
  }
  .each-slide-effect > div{
    height: 330px;
  }
  .main-heading{
    font-family: 'Poppins';
    text-align: left;
    letter-spacing: 0.72px;
    font-size: 30px;
    font-weight:600;
    color: #111111;
    width: 100%;
  }
}
@media only screen and (min-width:769px) and (max-width:991px){
  .navbar-nav{
    margin-top: -5px;
  }
  .show-grid{
    margin-top: 130px;
  }
  .rect-text{
    background: #DEF9EC 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
    padding: 30px 40px;
    margin-top: 15px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .customize-heading1{
    text-align: center;
    width: 95%;
    margin:0 auto;
  }
  .slide-heading{
    margin-left: 45px;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
  }
  .development-head{
    margin-left: 45px;
    margin-top: 45px;
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: bold;
  }
  .slide-txt{
    color: #008848;
    font-size: 20px;
    line-height: 28px;
    margin-left: 45px;
    width: 80%;
  }
  .each-slide-effect > div{
    height: 280px;
  }
  .main-heading{
    font-family: 'Poppins';
    text-align: left;
    letter-spacing: 0.72px;
    font-size: 30px;
    font-weight:600;
    color: #111111;
    width: 100%;
  }
  .main-heading-1{
    font-family: 'Poppins';
    text-align: left;
    letter-spacing: 0.72px;
    font-size: 35px;
    font-weight:600;
    margin-bottom: 20px;
    color: #111111;
    width: 85%;
  }
  .paragraph{
    font-family: 'Poppins';
    text-align:left;
    color: #222222;
    opacity: 1;
    padding-right: 0.75rem;
    font-size: 16px;
    line-height: 23px;
  }
  .para-div{
    padding:40px 0px;
  }
  .para-div1{
    padding: 10px 0px 40px 0px;
  }
  .footer{
    max-width: 100%!important;
    background: #fafafa 0% 0% no-repeat padding-box;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .foot-heading{
    margin-bottom: 20px;
  }
  .nav-link.contact-us{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: rgba(0,0,0,.55)!important;
    font-family:'Poppins';
    letter-spacing: 0.32px;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
    font-size: 16px;
    border: none;
    margin-left:0px!important;
  }
  .owner-heading{
    font-family: 'Poppins';
    text-align: center;
    letter-spacing: 0px;
    font-size: 20px;
    font-weight:400;
  }
  .owner-div1{
    padding: 40px 10px 20px 40px;
    border-radius: 30px;
    background-color:#e8e8e8;
    height: 228px;
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    z-index: 0;
    margin-top: -50px;
  }
  .owner-paragraph{
    height: 50%;
  }
  .btn2{
    margin-bottom: 10px;
  }
  .heading-end{
    margin-top: 40px;
  }
  .Demo-row{
    margin-top: 20px;
  }
  .customize-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .rect-text1{
    background: #DEF9EC 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
    margin-top: 15px;
    padding: 30px 40px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .nav{
    margin-top: 60px;
  }
}
@media only screen and (max-width:768px){
.dropdown-menu.show{
  text-align:center;
}
.show-grid{
  margin-top: 130px;
}
.navbar-nav{
  margin-top: -5px;
}
.customize-heading1{
  text-align: center;
  width: 98%;
  margin:0 auto;
}
.rect-bttn{
  margin-top: 10px;
}
.rect-text1{
  margin-top: 15px;
}
.rect-text{
  background: #DEF9EC 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  padding: 30px 40px;
  margin-top: 15px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}
.services_rectangle{
  width: 92%;
  margin: 10 auto;
}
.line{
  background: #19CE78 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 4px;
  width: 30%;
  margin-top: 30px;
  margin-bottom: 5px;
}
.customize{
  font-family: 'Poppins';
  font-weight:600;
  font-size: 28px;
  letter-spacing: 0.72px;
  color: #2B4137;
  opacity: 1;
  line-height:40px;
  margin-bottom: 25px;
}
.nav-link.contact-us{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: rgba(0,0,0,.55)!important;
  font-family:'Poppins';
  letter-spacing: 0.32px;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;
  margin-left: 0px!important;
  border: none;
  text-align: center;
}
.each-slide-effect > div{
  height: 370px;
}
.slide-heading{
  margin: 10px;
  font-family: 'Poppins';
  font-size: 26px;
  font-weight: 600;
}
.development-head{
  margin-left: 0px;
  margin-top: 45px;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: bold;
}
.slide-txt{
  color: #008848;
  font-size: 20px;
  line-height: 34px;
  margin-left: 10px;
  width: 100%;
}  
.main-heading{
  font-size: 28px;
  width: 90%;
}
.main-heading-1{
  font-family: 'Poppins';
  text-align: left;
  letter-spacing: 0.72px;
  font-size: 35px;
  font-weight:600;
  margin-bottom: 20px;
  color: #111111;
  width: 100%;
}
.paragraph{
  font-family: 'Poppins';
  text-align:left;
  color: #222222;
  opacity: 1;
  padding-right: 0.75rem;
  font-size: 16px;
  line-height: 23px;
}
.box-div{
  width: 100%;
}
.bttn{
  padding: 30px 30px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 60px;
  width: 96%;
  margin: 0 auto;
}
.navlink{
  font-size: 15px;
  color:#222222;
}
.navlink1{
  font-size: 15px;
  color:#222222;
}
.para-div{
  padding:30px 0px;
}
.para-div1{
  padding: 0px 0px 40px 10px;
}
.owner-heading{
  font-family: 'Poppins';
  text-align: center;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight:600;
}
.owner-div1{
  padding: 40px 10px 20px 40px;
  border-radius: 30px;
  background-color:#e8e8e8;
  height: 228px;
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  z-index: 0;
  margin-top: -50px;
}
.social-follow{
  margin-top: -15px;
  margin-bottom: 10px;
}
.Demo-row{
  margin-top: 20px;
}
.request-demo{
  margin-top: 10px;
}
.footer{
  max-width: 100%!important;
  background: #fafafa 0% 0% no-repeat padding-box;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn2{
  margin-bottom: 15px;
}
.row-end{
  margin-top: 30px;
  border-top: 2px solid #E8E8E8;
}
.footer-text{
  width: 100%;
}
.nav{
  margin-top: 60px;
}
.banner-row{
  margin-top:85px;
}
.website-heading{
  margin-top: 60px;
  font-size: 28px;
  font-weight: 700;
}
.dev-heading{
  text-align: left;
  font-size:28px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 100%;
  margin: 0;
}
.dev-para{
  text-align: left;
  font-size:18px;
  line-height: 27px;
  padding-top: 10px;
  padding-bottom: 40px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.development-process-2>div{
  padding: 20px;
}
.experience-rect-1{
  padding: 20px 20px 20px 20px;
}
}
@media only screen and (max-width:410px){
  .btn{
    font-size: 12px;
  }
  .each-slide-effect > div{
    height: 330px;
  }
  .slide-heading{
    margin: 5px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
  }
  .slide-txt{
    color: #008848;
    font-size: 18px;
    line-height: 34px;
    margin-left: 5px;
    width: 100%;
  }
  .foot-col h3{
    font-size: 22px;
    color: #111111;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: left;
    line-height: 40px;
  }
  .request-demo h3{
    text-align: left;
    font-size: 32px;
    line-height: 46px;
    font-weight: 600;
    width: 100%;
    font-family:'Poppins';
    letter-spacing: 0.72px;
    color: #111111;
    opacity: 1;
  }
  .owner-heading{
    font-family: 'Poppins';
    text-align: center;
    letter-spacing: 0px;
    font-size: 19px;
    font-weight:600;
  }
  .owner-div1{
    margin-top: -40px;
  }
  .para-div-1{
    margin: 40px 0;
  }
  .experience-paragraph{
    padding-left: 20px;
  }
}
@media only screen and (max-width:350px){
  .main-heading{
    font-size: 20px;
  }
  .btn{
    font-size: 10px;
  }
  .btn2{
    width: 100px;
    font-size: 11px;
  }
  .customize-heading .customize{
    width: 100%;
    margin-top: -50px;
  }
  .App{
    transform:scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}